<template>
  <header>
    <div class="logo-wrapper pl-3">
      <!-- <img :src="logo" alt="Clickbox"> -->
      IL Card
      <div class="env" v-if="env !== 'production'">{{env}}</div>
    </div>
    <b-button 
      variant="link"
      size="lg"
      class="nav-toggle" 
      @click="toggle"
    >
      <svg class="icon" :class="{expanded}" viewBox="0 0 24 24"><g class="icon__group" fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"><path d="M1 6h22"></path><path d="M1 12h22"></path><path d="M1 18h22"></path></g></svg>
    </b-button>
  </header>
</template>

<script>
import logo from '@/assets/logo.png'

export default {
  data() {
    return {
      logo,
      expanded: false
    }
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
      this.$emit('toggle',this.expanded)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/css/variables.scss';

  .nav-toggle {
    color: $header-color;
    padding: .5rem;
    &:focus {
      box-shadow: none;
    }

    @media(min-width: 1024px) {
      display: none;
    }

    .icon {
      --size: 1.25em;
      --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
      height: var(--size);
      width: var(--size);
      display: block;
      color: inherit;
      fill: currentColor;
      line-height: 1;
      flex-shrink: 0;
      max-width: initial;
  
      .icon__group>* {
        stroke-dasharray: 24;
        transition: stroke-dashoffset .3s,opacity .3s,-webkit-transform .3s var(--ease-in-out);
        transition: transform .3s var(--ease-in-out),stroke-dashoffset .3s,opacity .3s;
        transition: transform .3s var(--ease-in-out),stroke-dashoffset .3s,opacity .3s,-webkit-transform .3s var(--ease-in-out);
      }

      .icon__group {
        stroke-width: 2px;
  
        &>*:nth-child(1) { transform-origin: 12px 6px; }
        &>*:nth-child(2) { stroke-dashoffset: 0; }
        &>*:nth-child(3) { transform-origin: 12px 18px; }
      }

      &.expanded .icon__group {
        &>*:nth-child(1) { transform: translateY(6px) rotate(-45deg); }
        &>*:nth-child(2) { stroke-dashoffset: 24; }
        &>*:nth-child(3) { transform: translateY(-6px) rotate(45deg); }
      }
    }
  }
</style>