import access from "./access";

export default [
  {
    $roles: access['dashboard'],
    route: 'dashboard',
    icon: 'bxs-dashboard',
    title: 'dashboard.title'
  },
  {
    $roles: access['applications'],
    route: 'applications',
    icon: 'bxs-credit-card-front',
    title: 'applications.title'
  },
  {
    $roles: access['users'],
    route: 'users',
    icon: 'bxs-group',
    title: 'users.title'
  },
]