import { ROLE_ALL, ROLE_SUPERADMIN } from "./resources/User"

export default {
  'dashboard': ROLE_ALL,

  'applications': ROLE_SUPERADMIN,
  'applications-show': ROLE_SUPERADMIN,
  'applications-edit': ROLE_SUPERADMIN,

  'users': ROLE_SUPERADMIN,
  'users-show': ROLE_SUPERADMIN,
  'users-create': ROLE_SUPERADMIN,
  'users-edit': ROLE_SUPERADMIN,
  'users-profile': ROLE_SUPERADMIN,
}