
/* Fields ************************************************************/

import { ROLE_ADMIN } from "./User"

export const idField = () => ({ 
  key: 'id', 
  sortable: true,
  label: 'common.id',
})

export const nameField = (sortKey = 'name') => ({ 
  sortKey,
  key: 'name', 
  sortable: true,
  label: 'common.name',
})

export const activeField = () => ({ 
  $roles: ROLE_ADMIN,
  key: 'active', 
  label: 'common.active',
  formatter: (v,k,item) => item.active
})


/* Filters ***********************************************************/

export const activeFilter = () => ({
  $roles: ROLE_ADMIN,
  name: 'active',
  label: 'common.active',
  default: true,
  type: 'boolean',
})

/* Batch actions *****************************************************/

export const deleteBatchAction = () => ({
  name: 'delete',
  icon: 'bx-trash',
  label: 'item.delete',
  variant: 'danger',
  confirm: true,
  async action(item) {
    return await item.$delete()
  }
})