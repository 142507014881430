import api from "@/api";
import Resource from "@/services/Resource";
// import api from "@/api"
import Vue from "vue";
// import i18n from "@/i18n";
import { deleteBatchAction, idField, nameField } from "./common";
// import store from "@/store";
// import { download } from "@/utils";
import Export from "@/components/Modals/Export"
import { download } from "@/utils";
import printer from "@/printer";

export const STATUS_CANCELLED    = -100
export const STATUS_DENIED       = -50
export const STATUS_DRAFT        = 0
export const STATUS_AWAITING_OTP = 10;
export const STATUS_SUBMITTED    = 20
export const STATUS_PAUSED       = 30
export const STATUS_APPROVED     = 50
export const STATUS_EXPORTED     = 60
export const STATUS_PRINTED      = 70
export const STATUS_COMPLETED    = 100

export default class Application extends Resource {
  static name = 'application'
  static icon = 'bx-credit-card-front'

  static creatable = false

  static get defaultTableState() {
    return {
      ...super.defaultTableState,
      sortBy: "updated_at",
      sortDesc: true,
    }
  }

  static fields = [
    idField(),
    nameField('last_name'),
    {
      key: 'status',
      label: 'common.status',
      sortable: true,
    },
    { 
      key: 'created_at', 
      sortable: true,
      label: 'common.created_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
    { 
      key: 'updated_at', 
      sortable: true,
      label: 'common.updated_at',
      formatter: (value) => Vue.filter('moment')(value)
    },
  ]

  static filters = [
    {
      type: 'date',
      name: 'created_at',
      label: 'common.created_at',
    },
    {
      type: 'date',
      name: 'updated_at',
      label: 'common.updated_at',
    },
    {
      type: 'select',
      name: 'status',
      label: 'applications.status',
      options: this.statuses.filter(s => !s.hidden),
      multiple:  true,
    },
  ]

  static actions = [
    // {
    //   name: 'deny',
    //   icon: 'bx-task-x',
    //   variant: 'danger',
    //   async action(item) {
    //     await item.$deny()
    //     return {refresh: true}
    //   }
    // },
    {
      name: 'pause',
      icon: 'bx-pause',
      async action(item) {
        await item.$pause()
        return {refresh: true}
      }
    },
    {
      name: 'print',
      icon: 'bx-printer',
      variant: 'info',
      async action(item) {
        await item.$print()
        return {refresh: true}
      }
    },
    {
      name: 'approve',
      icon: 'bx-task',
      variant: 'success',
      confirm: true,
      confirmText: 'applications.actions.approve-confirm',
      confirmOk: 'applications.actions.approve',
      confirmVariant: 'success',
      async action(item) {
        await item.$approve()
        return {refresh: true}
      }
    },
  ]

  static batchActions = [
    {
      name: 'export',
      icon: 'bx-export',
      label: 'applications.batch-actions.export',
      modal: Export,
    },
    deleteBatchAction(),
  ]

  static relations = [
  ]

  get badges() {
    const badges = []

    // if(this.verified) {
    //   badges.push(      {
    //     icon: 'bx-check-shield',
    //     variant: 'success',
    //   })
    // }

    return badges
  }

  get defaults() {

    return {
    }
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  async $getSignature() {
    const {data} = await api.get(`applications/${this.id}/signature`,{
      responseType: 'blob'
    })

    return window.URL.createObjectURL(data);
  }

  // async $deny() {
  //   return api.patch(`applications/${this.id}/deny`)
  // }

  async $approve() {
    return api.patch(`applications/${this.id}/approve`)
  }

  async $pause() {
    return api.patch(`applications/${this.id}/pause`)
  }

  async $print() {
    let {data} = await api.post(`applications/${this.id}/print`,undefined,{ 
      responseType: 'blob',
    })

    printer.$emit('printer:print',new Blob([data], {type: 'application/pdf'}))

    return true
  }

  static async export(ids,format = 'XLSX') {
    let response = await api.get('applications/export',{
      params: {ids,format},
      responseType: 'blob'
    })

    download(response)

    return true
  }

  static get statuses() {
    return [
      { label: `applications.statuses.${STATUS_CANCELLED}`, code: STATUS_CANCELLED, icon: 'bx-trash' },
      { label: `applications.statuses.${STATUS_DENIED}`, code: STATUS_DENIED, icon: 'bx-task-x', variant: 'danger' },
      { label: `applications.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank' },
      { label: `applications.statuses.${STATUS_AWAITING_OTP}`, code: STATUS_AWAITING_OTP, icon: 'bx-phone', variant: 'muted' },
      { label: `applications.statuses.${STATUS_SUBMITTED}`, code: STATUS_SUBMITTED, icon: 'bxs-inbox' },
      { label: `applications.statuses.${STATUS_PAUSED}`, code: STATUS_PAUSED, icon: 'bx-pause-circle', variant: 'muted' },
      { label: `applications.statuses.${STATUS_APPROVED}`, code: STATUS_APPROVED, icon: 'bx-task', variant: 'success' },
      { label: `applications.statuses.${STATUS_EXPORTED}`, code: STATUS_EXPORTED, icon: 'bxs-file-export' },
      { label: `applications.statuses.${STATUS_PRINTED}`, code: STATUS_PRINTED, icon: 'bx-printer' },
      { label: `applications.statuses.${STATUS_COMPLETED}`, code: STATUS_COMPLETED, icon: 'bx-check-circle', variant: 'dark' },
    ]
  }
}

Resource.registerResource(Application)