import store from '@/store'
import Resource from "@/services/Resource";
import Token from "@/components/Modals/Token";
import i18n, { availableLocales } from '@/i18n';
import { activeField, activeFilter, deleteBatchAction, nameField } from './common';
import { modal } from '@/utils';
import api from '@/api';

export const ROLE_SUPERADMIN       = 1

export const ROLE_ADMIN    = ROLE_SUPERADMIN
export const ROLE_ALL      = ROLE_SUPERADMIN

export const roleMap = {
  [ROLE_SUPERADMIN]: 'role.superadmin',
}

export default class User extends Resource {
  static name = 'user'
  static icon = 'bx-group'

  static relations = [
  ]

  static fields = [
    nameField('last_name'),
    { 
      key: 'email', 
      sortable: true,
      label: 'common.email'
    },
    { 
      key: 'role', 
      sortable: true,
      label: 'users.role',
      formatter: (value) => i18n.t(roleMap[value])
    },
    activeField(),
  ]
  
  static filters = [
    activeFilter(),
    {
      $roles: ROLE_ADMIN,
      name: 'role',
      label: 'users.role',
      type: 'select',
      options: this.roles,
    },
  ]


  static actions = [
    {
      name: 'request-token',
      icon: 'bxs-key',
      async action(item) {
        await item.$requestToken()
        return {refresh: false}
      }
    }
  ]

  static batchActions = [
    deleteBatchAction()
  ]

  get roleName() {
    return roleMap[this.role]
  }

  static get locales() {
    return availableLocales
  }

  get defaults() {
    return {
      active: 1,
    }
  }

  get name() {
    return `${this.first_name} ${this.last_name}`
  }

  get isSuperAdmin() {
    return this.role & ROLE_SUPERADMIN
  }

  static get roles() {
    return [
      {label: i18n.t(roleMap[ROLE_SUPERADMIN]), code: ROLE_SUPERADMIN},
    ]
  }

  async $requestToken() {
    let {data} = await api.get(`users/${this.id}/request-token`)

    await modal(Token,{token: data.token})
  }
}

export const roleFilter = obj => !obj.$roles || store.getters['auth/role'] & obj.$roles

Resource.registerResource(User)