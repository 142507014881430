<template>
  <MainLayout fluid>
      <ShipmentsTable>
        <template #actions>
          <b-button
            v-if="$access('shipments-import')"
            :to="{ name: `shipments-import` }"
            class="mr-2 mb-2"
          >
            <i class='bx bx-import'></i>
            {{ $t("shipments.import-items") }}
          </b-button>
        </template>
      </ShipmentsTable>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemTableTemplate from '@/components/ItemTableTemplate'
import ItemTable from '@/components/ItemTable'
import Shipment from '@/resources/Application'

const ShipmentsTable = {
  ...ItemTableTemplate,
  mixins: [ItemTable(Shipment)]
}

export default {
  components: { MainLayout, ShipmentsTable },
  metaInfo() {
    return {
      title: this.$t('shipments.title'),
    }
  },
};
</script>