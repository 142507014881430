<template>
  <v-select
    v-model="internalValue"
    :deselect-from-dropdown="multiple || bitmap"
    v-bind="{options, clearable, required, disabled, multiple: multiple || bitmap, selectable, placeholder}"
    v-on="{...$listeners,input:undefined}"
    label="label"
    appendToBody
    :reduce="reduce" 
  >
    <template #option="option">
      <i v-if="option.icon" :class="['small','bx',option.icon,'mr-1']" />
      {{$t(option.label)}}
    </template>
    <template #selected-option="option">
      <i v-if="option.icon" :class="['small','bx',option.icon,'mr-1']" />
      {{$t(option.label)}}
    </template>
    <template #no-options>{{$t('ui.select-no-results')}}</template>
  </v-select>
</template>

<script>
import { bitsToInt, intToBits } from '@/utils';

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String,Number],
      default: null,
    },
    placeholder: {
      type: [String,Number],
      default: null,
    },
    clearable: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    bitmap: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Function,
      default: undefined
    },
    reduce: {
      type: Function,
      default: opt => opt.code
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.bitmap ? intToBits(this.value) : this.value
      },
      set(value) {
        this.$emit('input', this.bitmap ? bitsToInt(value) : value)
      }
    },
  },
};
</script>