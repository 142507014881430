<template>
  <b-card class="mb-2 action-bar" v-if="show">
    <div class="spread">
      <div>
        <b-button v-if="item.$can('delete')" @click="$emit('action','delete')" variant="outline-danger">
          <i class='bx bx-trash' ></i>
          {{ $t("item.delete") }}
        </b-button>
      </div>
      <div class="spread">
        <template v-for="action of resource.actions">
          <action-button :key="action.name" v-bind="{item,resource,action}" />
        </template>

        <b-button v-if="item.$can('edit')" :to="{ name: `${resource.plural}-edit`, params: {id: item.id} }" variant="primary">
          <i class='bx bx-pencil' ></i>
          {{ $t("item.edit") }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import Resource from '@/services/Resource'
import ActionButton from './ActionButton'
export default {
  components: {ActionButton},
  props: {
    item: {
      type: Resource,
      required: true
    },
    resource: {
      type: Function,
      required: true
    }
  },
  computed: {
    show() {
      return this.item.$can('delete') 
          || this.item.$can('edit') 
          || this.resource.actions.some(a => a.static || this.item.$can(a.name))
    }
  }
}
</script>