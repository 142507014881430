<template>
  <b-card>
    <template #header> 
      <b-button 
        class="float-right"
        variant=""
        @click="reversed = !reversed"
      >
        <i class='bx' :class="{'bx-sort-up': reversed, 'bx-sort-down': !reversed}" ></i>
      </b-button>
      <i class='bx bx-list-ul' />
      {{$t('common.activity')}}
    </template>
    <div v-if="events">
      <dl>
        <value 
          v-for="(event,i) in sortedEvents" 
          :key="i" 
          :raw-label="event.date | moment('LLLL')" 
          :icon="getIcon(event)"
          :value="true"
        >
          <template v-if="event.type === 'status'">
            {{$t(`common.status`)}}: <status v-bind="{item}" :value="event.data" />
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span>
          </template>
          <template v-else-if="event.type === 'clickbox-status'">
            {{$t(`applications.clickbox-status`)}}: <status :item="new Package" :value="event.data" />
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span>
          </template>
          <template v-else>
            {{$te(`${resource.plural}.events.${event.type}`) ? $t(`${resource.plural}.events.${event.type}`,event) : event.type}}
            <span v-if="event.user" class="text-muted">{{$t(`events.by`,{user: event.user})}}</span>
            <div v-if="event.data" class="text-initial">{{event.data}}</div>
          </template>
        </value>
      </dl>
      <span v-if="item.updated_at" class="small muted">
        {{$t('common.updated_at')}}:
        {{item.updated_at | moment('LLLL')}}
      </span>
    </div>
    <dl v-else>
      <value label="common.created_at" icon="bxs-circle" :value="item.created_at | moment('LLLL')" />
      <value label="common.updated_at" icon="bxs-circle" :value="item.updated_at | moment('LLLL')" />
    </dl>
    <slot />
  </b-card>
</template>

<script>
import Value from "@/components/Value";
import Status from "@/components/Status";
import Resource from '@/services/Resource';
import Package from '@/resources/Package'

export default {
  components: {
    Value,
    Status,
  },
  data() {
    return {
      Package
    }
  },
  props: {
    item: {
      type: Resource,
      required: true
    }
  },
  computed: {
    resource() {
      return this.item.constructor
    },
    events() {
      if('events' in this.item.$meta) {
        return this.item.$meta.events
      } else {
        return null
      }
    },
    sortedEvents() {
      return this.reversed ? Object.assign([], this.events).reverse() : this.events
    },
    reversed: {
      get () {
        return this.$store.state.activitiesReversed
      },
      set (reversed) {
        this.$store.commit(`setActivitiesReversed`, reversed)
      },
    },
  },
  methods: {
    getIcon(event)  {
      switch(event.type) {
        case 'created':
          return 'bxs-star'
        case 'status':
          return 'bxs-analyse'
        case 'otp-requested':
        case 'otp-verified':
          return 'bxs-phone'
        default:
          return 'bxs-circle'
      }
    }
  }
}
</script>