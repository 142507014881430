import Vue from 'vue'
import { transMap, capitalize, ucfirst } from './utils';
import i18n from './i18n'
import moment from 'moment';

Vue.filter('moment', (date,format = 'LLL') => {
  return moment(date).locale(i18n.locale).format(format);
})
Vue.filter('transMap', (arr, first = 'label', ...rest) => transMap(arr,first,...rest))
Vue.filter('capitalize', capitalize)
Vue.filter('ucfirst', ucfirst)
