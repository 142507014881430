import User from '@/resources/User'
import Application from '@/resources/Application'

export default [
    {
        name: 'signin',
        path: '/signin',
        component: () => import(/* webpackChunkName: "signin", webpackMode: "eager" */ `@/pages/SignIn`),
        meta: {
            guest: true
        }
    },
    {
        name: 'reset-password',
        path: '/reset-password',
        component: () => import(/* webpackChunkName: "reset-password" */ `@/pages/ResetPassword`),
        meta: {
            guest: true
        }
    },
    {
        name: 'create-password',
        path: '/create-password',
        component: () => import(/* webpackChunkName: "create-password" */ `@/pages/CreatePassword`),
        meta: {
            guest: true
        }
    },
    {
        name: 'dashboard',
        path: '/',
        component: () => import(/* webpackChunkName: "dashboard", webpackMode: "eager" */ `@/pages/Dashboard`),
    },
    ...Application.routes,
    {
        name: `users-profile`,
        path: `/users/profile`,
        component: () => import(`@/pages/users/Edit`),
        meta: {
          mode: 'edit',
          profile: true,
          resource: User, 
        }
    },
    ...User.routes,
]