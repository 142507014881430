<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">

        <b-card>
          <template #header>
            <i class="bx bxs-contact"></i>
            {{ $t("applications.details") }}
          </template>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input
                label="common.first_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.first_name"
              />
            </div>
            <div class="col">
              <form-input
                label="common.middle_name" 
                icon="bx-purchase-tag"
                v-model="item.middle_name"
              />
            </div>
            <div class="col">
              <form-input
                label="common.last_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.last_name"
              />
            </div>
          </div>

          <form-input
            label="common.phone" 
            icon="bx-phone"
            rules="required"
            v-model="item.phone"
          />

          <form-input
            type="email"
            label="common.email" 
            icon="bx-envelope"
            rules="required|email"
            v-model="item.email"
          />
        </b-card>

      </div>
      <div class="col mb-4">

      </div>
    </div>

    <template #buttons="{invalid,touched}" v-if="creating">
      <b-button :disabled="invalid || !printable" @click="saveAndPrint" :variant="printable ? 'primary' : null" class="float-right ml-2">
        {{ $t("shipments.create-and-print") }}
      </b-button>
      <b-button :disabled="invalid" @click="save" :variant="printable ? null : 'primary'" class="float-right">
        {{ $t("shipments.create-as-draft") }}
      </b-button>
      <span v-if="touched && invalid" class="p-2 text-danger float-right">{{$t('validation.form-invalid')}}</span>
      <b-button @click="cancel" variant="light">
        {{ $t("item.cancel") }}
      </b-button>
    </template>

  </ItemEditTemplate>
</template>

<script>
import ItemEdit from '@/components/ItemEdit'
import ItemEditTemplate from '@/components/ItemEditTemplate'
import FormInput from "@/components/Form/Input";
import PhoneInput from '@/components/Form/PhoneInput';

export default {
  extends: ItemEdit,
  components: { 
    ItemEditTemplate, 
    FormInput, 
    PhoneInput
  },
  data() {
    return {
    }
  },
  computed: {
    locales() {
      return this.$i18n.availableLocales.reduce((c,v) => (c.push({code:v,label:`locale.${v}`}),c),[])
    }
  },
  methods: {
  }
};
</script>