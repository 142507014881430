import { roleFilter } from '@/resources/User'
import { mapFields } from 'vuex-map-fields';
import CountryFlag from 'vue-country-flag'
import debounce from 'lodash.debounce'
import ItemFilter from '@/components/Filter'
import Debug from '@/components/Debug'
import Status from '@/components/Status'
import { mapGetters, mapMutations } from 'vuex';
import ActionModal from "@/components/Modals/Action"
import { ucfirst } from '@/utils';

export default (resource) => ({
  components: {CountryFlag, ItemFilter, Status, Debug},
  props: {
    permFilters: {
      type: Array,
      default: Array
    },
    createButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: true,
      selected: [],
      totalRows: null,
      actionRunning: false,
    };
  },
  computed: {
    resource: {
      get() {
        return resource
      }
    },
    ...mapFields(resource.plural,[
      'perPage',
      'currentPage',
      'sortBy',
      'sortDesc',
      'search',
      'scope',
    ]),
    ...mapGetters(resource.plural,[
      'filters',
      'hasFilter',
    ]),
    stats() {
      let [p,i,t] = [this.currentPage,this.perPage,this.totalRows]
      return {
        from: ((p-1)*i+1),
        to: Math.min(p*i,t),
        total: t,
      }
    },
    fields() {
      let fields = resource.fields
        .filter(roleFilter)
        .filter(field => !field.$scopes || field.$scopes.includes(this.scope))

      return [
        this.hasBatchActions ? {
          key: 'selected',
          label: '',
          stickyColumn: true,
          class: 'row-select',
        } : null,
        ...fields,
      ].filter(Boolean).filter(field => this.columns.includes(field.key) || field.key === 'selected')
    },
    availableFilters() {
      return resource.filters
        .filter(roleFilter)
    },
    fieldOptions() {
      return resource.fields
        .filter(roleFilter)
        .filter(field => !field.$scopes || field.$scopes.includes(this.scope))
    },
    columns: {
      get () {
        let columns = this.$store.state[resource.plural].columns
        if(!columns.length) {
          columns = this.fieldOptions.map(opt => opt.key)
          this.$store.commit(`${resource.plural}/setColumns`, columns)
          this.$forceUpdate()
        }
        return columns
      },
      set (columns) {
        this.$store.commit(`${resource.plural}/setColumns`, columns)
      },
    },
    params() {
      return {
        limit: this.perPage,
        page: this.currentPage,
        sort: this.sortBy,
        desc: this.sortDesc,
        search: this.search,
        scope: this.scope,
        filters: this.getFilterQuery()
      }
    },
    hasBatchActions() {
      return resource.batchActions.length
    }
  },
  watch: {
    scope() {
      this.reset(true)
    },
  },
  created() {
    let {filters} = this.$route.params

    if(filters) {
      this.reset(false,false)
      for(let name in filters) {
        this.setFilter({name,value: filters[name]});
      }
    }

    this.loading = false
  },
  methods: {
    ...mapMutations(resource.plural,[
      'resetTable',
      'setFilter',
      'removeFilter',
      'clearFilters',
    ]),
    refresh() {
      this.$refs.itemTable.refresh()
    },
    reset(soft = false, refresh = true) {
      if(soft === true) {
        this.clearFilters()
        this.clearSelection()
        this.currentPage = 1
      } else {
        this.resetTable(this.resource)
      }

      refresh && this.refresh()
    },
    async provider() {
      try {
        let response = await resource.list(this.params)
        let {total, current_page, last_page} = response.meta
        this.totalRows = total

        if(current_page > last_page) this.currentPage = last_page
        return response.data || []
      } catch (error) {
        console.error(error)
        return []
      }
    },
    select(item) {
      this.selected.push(item.id)
    },
    unselect(item) {
      let i = this.selected.indexOf(item.id)
      if(~i) {
        this.selected.splice(i,1)
      }
    },
    async selectAll() {
      this.selected = await resource.list({list_ids: true, ...this.params})
    },
    clearSelection() {
      this.selected = []
    },
    getFilter(name) {
      let filter =  {...this.availableFilters.find(f => f.name == name)}
      delete filter.$roles
      return filter
    },
    getFilterQuery() {
      let convertBooleans = v => typeof v == "boolean" ? (v ? 1 : 0) : v
      return [...this.filters,...this.permFilters].filter(f => f.value?.length).map(f => [f.name,...f.value.map(convertBooleans)])
    },
    applyFilters() {
      this.currentPage = 1
      this.clearSelection()
      this.refresh()
    },
    rowStyling(item) {
      let classes = []
      if(item) {
        if(this.selected.includes(item.id)) {
          classes.push('b-table-row-selected','table-active')
        }
        if('active' in item && !item.active) {
          classes.push('inactive')
        }
      }

      return classes
    },
    debounceSearch:  debounce(function(value) {
      this.search = value
    },500),
    async performAction(action) {
      this.actionRunning = true
      let confirm = false
      let {can,cannot,allOk,reasons} = await this.resource.can(action.name,this.selected,{or: action.or})

      let confirmOptions = {
        cancelTitle: this.$t('common.cancel'),
        okTitle: this.$t(action.label),
        okVariant: action.variant
      }

      let firstReason = Object.values(reasons).shift()// || 'common.errors.unknown-reason'

      if(allOk) {
        confirm = !action.confirm || await this.$bvModal.msgBoxConfirm(ucfirst(this.$t(`actions.${action.name}.confirm`,{
          items: this.$tc(`${this.resource.plural}.pluralize`,can.length)
        })),confirmOptions)
      } 
      else if(can.length) {
        confirm = await this.$bvModal.msgBoxConfirm(ucfirst(this.$t(`actions.${action.name}.confirm-partial`,{
          items: this.$tc(`${this.resource.plural}.pluralize`,cannot.length),
          rest: can.length,
          reason: this.$t(firstReason),
        })),confirmOptions)
      } 
      else {
        await this.$bvModal.msgBoxOk(this.$tc(`actions.${action.name}.none-eligible`,cannot.length,{
          item: this.$t(`${this.resource.plural}.singular`),
          items: this.$tc(`${this.resource.plural}.pluralize`,cannot.length),
          reason: this.$t(firstReason),
        }),{
          okTitle: this.$t('common.ok'),
        })
      }

      if(confirm) {
        let modal = action.modal || ActionModal

        let result = await this.$modal(modal,{
          action,
          resource: this.resource,
          items: can
        })

        if(result) {
          this.selected = cannot.map(s => s.id)
          this.refresh()
        }
      }

      this.actionRunning = false
    }
  },
});