<script>
export default {
  functional: true,
  props: {
    label: String,
    rawLabel: String,
    icon: String,
    value: [String,Number,Array,Boolean],
    ddClass: [String,Object,Array],
  },
  render(h,{props,children,parent,data}) {
    let empty
    if(Array.isArray(props.value)) {
      empty = !props.value.length
    } else {
      empty = [null,undefined,''].includes(props.value)
    }

    let content = children?.length ? children : !empty ? [props.value.toString()] : []

    if(empty) {
      content.push(h('span',{class:'no-value'},parent.$t('common.no-value')))
    }

    return [
      h('dt', [
        props.rawLabel || parent.$t(props.label),
      ]),
      h(
        'dd', 
        data,
        [h('i',{ class: ['bx', props.icon] })].concat(content.filter(Boolean))),
    ]
  }
}
</script>