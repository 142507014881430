import Resource from "@/services/Resource"

export const STATUS_EXPIRED_COLLECTED = -100
export const STATUS_EXPIRED           = -50
export const STATUS_REPLACED          = -5
export const STATUS_DRAFT             = 0
export const STATUS_REGISTERED        = 20
export const STATUS_AWAITING_PICKUP   = 30
export const STATUS_PICKED_UP         = 50
export const STATUS_IN_WAREHOUSE      = 60
export const STATUS_DROPPED_OFF       = 70
export const STATUS_READY_TO_COLLECT  = 90
export const STATUS_COLLECTED         = 100

export default class Package extends Resource {
  static name = 'package'
  static icon = 'bx-package'

  static get statuses() {
    return [
      { label: `packages.statuses.${STATUS_DRAFT}`, code: STATUS_DRAFT, icon: 'bx-file-blank' },
      { label: `packages.statuses.${STATUS_REGISTERED}`, code: STATUS_REGISTERED, timeline: true, icon: 'bx-printer' },
      { label: `packages.statuses.${STATUS_AWAITING_PICKUP}`, code: STATUS_AWAITING_PICKUP, timeline: true, icon: 'bx-upload' },
      { label: `packages.statuses.${STATUS_PICKED_UP}`, code: STATUS_PICKED_UP, timeline: true, icon: 'bxs-truck' },
      { label: `packages.statuses.${STATUS_IN_WAREHOUSE}`, code: STATUS_IN_WAREHOUSE, timeline: true, icon: 'bx-building' },
      { label: `packages.statuses.${STATUS_DROPPED_OFF}`, code: STATUS_DROPPED_OFF, timeline: true, icon: 'bx-download' },
      { label: `packages.statuses.${STATUS_READY_TO_COLLECT}`, code: STATUS_READY_TO_COLLECT, timeline: true, icon: 'bx-user-check' },
      { label: `packages.statuses.${STATUS_COLLECTED}`, code: STATUS_COLLECTED, timeline: true, icon: 'bx-check-circle' },

      { label: `packages.statuses.${STATUS_EXPIRED}`, code: STATUS_EXPIRED, variant: 'danger', icon: 'bx-time-five' },
      { label: `packages.statuses.${STATUS_EXPIRED_COLLECTED}`, code: STATUS_EXPIRED_COLLECTED, variant: 'danger', icon: 'bx-check-circle' },
    ]
  }
}

Resource.registerResource(Package)