import pluralize from "pluralize"
import api from "@/api"
import router from "@/router"
import { camelCase } from "@/utils"

const resources = {}

export default class Resource {
  #__meta = {}
  #__relations = {}

  static actions = []
  static batchActions = []
  static filters = []
  static relations = []
  static parent = null

  static creatable = true
  
  constructor(item = {}, meta = {}) {
    this.$fill(Object.assign(this.defaults,item),meta)
  }

  $fill(data,meta) {
    Object.assign(this,data)
    this.#__meta = meta
    this.#__populateRelations(meta)
  }

  #__populateRelations(meta) {
    for(let relation of this.constructor.relations) {
      if(relation.name in meta) {
        let property = `$${camelCase(relation.name)}`
        let resource = this.constructor.getResource(relation.resource)
        if(Array.isArray(meta[relation.name])) {
          this.#__relations[relation.name] = meta[relation.name].map(item => new resource(item))
        } else if(typeof meta[relation.name] === 'object'){
          this.#__relations[relation.name] = new resource(meta[relation.name])
        }
        
        property in this || Object.defineProperty(this, property, {
          get: () => this.#__relations[relation.name]
        });
      }
    }
  }

  static registerResource(resource) {
    resources[resource.plural] = resource
  }

  static getResource(resource) {
    if(resource in resources) {
      return resources[resource]
    } else {
      throw new Error(`Unknown resource ${resource}`)
    }
  }

  static get singular() {
    return pluralize(this.name,1)
  }

  static get plural() {
    return pluralize(this.name,Infinity)
  }

  static get scopes() {
    return []
  }

  get badges() {
    return []
  }

  static get defaultTableState() {
    return {
      search: null,
      perPage: 25,
      currentPage: 1,
      sortBy: "id",
      sortDesc: false,
      scope: null,
      filters: []
    }
  }

  static get routes() {

    let slug = this.plural.replace('_','-')
    let prefix = this.routePrefix || ''

    return [
        ...this.extraRoutes({prefix,slug}),
        {
            name: this.plural,
            path: `${prefix}/${slug}`,
            component: () => import(/* webpackChunkName: "[request]" */ `@/pages/${this.plural}/Index`),
            meta: { resource: this },
        },
        {
          name: `${this.plural}-create`,
          path: `${prefix}/${slug}/create`,
          component: () => import(/* webpackChunkName: "[request]" */ `@/pages/${this.plural}/Edit`),
          meta: {
            mode: 'create', 
            resource: this, 
          },
        },
        {
            name: `${this.plural}-show`,
            path: `${prefix}/${slug}/:id`,
            component: () => import(/* webpackChunkName: "[request]" */ `@/pages/${this.plural}/Show`),
            meta: { resource: this },
        },
        {
            name: `${this.plural}-edit`,
            path: `${prefix}/${slug}/:id/edit`,
            component: () => import(/* webpackChunkName: "[request]" */ `@/pages/${this.plural}/Edit`),
            meta: {
              mode: 'edit', 
              resource: this, 
            },
        }
    ]
  }

  static extraRoutes() {
    return []
  }

  static async list(params) {
    let { data } = await api.get(`${this.plural}`,{params})

    if(!params.light && !params.list_ids) {
      data.data  = data.data.map(item => new this(item))
    }
    
    return data
  }

  static async get(id) {
    let { data: {data, meta} } = await api.get(`${this.plural}/${id}`)
    let item = new this(data, meta)
    
    return item
  }

  static async can(action,ids,{or = null}) {
    let {data: {allOk,can,cannot,reasons}} = await api.get(`user/can/${action}/${this.singular}`,{params:{or,ids}})
    return {
      allOk,
      reasons,
      can: can.map(item => new this(item)),
      cannot: cannot.map(item => new this(item)),
    }
  }

  get defaults() {
    return {}
  }

  get $exists() {
    return !!this.id
  }

  get $meta() {
    return this.#__meta
  }

  $can(action) {
    return !!this.$meta.actions[action]
  }

  $open() {
    router.push({name: `${this.constructor.plural}-show`, params: { id: this.id }})
  }

  async $save() {
    let {data: {data, meta}} = this.$exists
      ? await api.put(`${this.constructor.plural}/${this.id}`,this)
      : await api.post(`${this.constructor.plural}`,this)

    this.$fill(data,meta)

    return data
  }

  async $refresh() {
    let { data: {data, meta} } = await api.get(`${this.constructor.plural}/${this.id}`)
    
    this.$fill(data,meta)
  }

  async $delete() {
    let response = await api.delete(`${this.constructor.plural}/${this.id}`)

    Object.keys(this).forEach(key => delete this[key])
    
    return response
  }
}