import Vue from 'vue';
import i18n from './i18n'
import store from './store'
import router from './router';
import { getField, updateField } from 'vuex-map-fields';

export const capitalize = e => e.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
export const ucfirst = ([first, ...rest]) => first && first.toUpperCase() + rest.join('')

export const camelCase = e => e.replace(/_([a-z])/g, (g) =>  g[1].toUpperCase())
export const snakeCase = e => e.match(/([A-Z])/g).reduce((str, c) => str.replace(new RegExp(c), '_' + c.toLowerCase()),e).substring((e.slice(0, 1).match(/([A-Z])/g)) ? 1 : 0)

export const intToBits = v => (v||0).toString(2).split('').reverse().map((x, i) => parseInt(x) ? 1 << i : 0).filter(Boolean)
export const bitsToInt = a => a.reduce((c,v) => c+v,0)

export const clean = (obj,emptyString = false) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || (emptyString && obj[propName] === '')) {
      delete obj[propName];
    }
  }
  return obj
}

export const transMap = (arr,...props) => {
  return arr.map(obj => {
    let copy = {...obj}
    for(let prop of props) {
      if(prop in copy) {
        copy[prop] = i18n.t(copy[prop])
      }
    }

    return copy
  })
}

export const setupResourceStore = (resource) => {
  const state = {
    ...resource.defaultTableState,
    columns: [],
  };
  
  const getters = {
    getField,
    filters: (state) => state.filters,
    hasFilter: (state) => (name) => {
      return !!~state.filters.findIndex(f => f.name == name)
    }
  };
  
  const actions = {
  };
  
  const mutations = {
    updateField,
    setColumns: (state,columns) => {
      state.columns = columns
    },
    resetTable: (state) => {
      Object.assign(state,resource.defaultTableState)
    },
    setFilter: (state,{name,value = null}) => {
      let index = state.filters.findIndex(f => f.name == name)
      if(~index) {
        Vue.set(state.filters,index,{ name, value })  
      } else {
        state.filters.push({name, value})
      }
    },
    removeFilter: (state,name) => {
      Vue.delete(state.filters,state.filters.findIndex(f => f.name == name))
    },
    clearFilters: (state) => {
      state.filters = []
    }
  };

  return {state,getters,actions,mutations}
}

export const download = ({data,headers}) => {
  let headerLine = headers['content-disposition']
  let filename = headerLine.substring(headerLine.indexOf('filename=')+9)
  let blob = new Blob([data], {type: headers['content-type']})

  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export const modal = (component,propsData = {}) => {
  return new Promise((resolve) => {
    new (Vue.extend(component))({
      data: { resolve },
      propsData,
      store,
      router,
      i18n
    })
  })
}