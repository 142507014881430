<template>
  <iframe :src="src" class="printer-iframe" ref="print" @load="loaded" />
</template>

<script>

export default {
  data() {
    return {
      src: undefined
    }
  },
  mounted() {
    this.$root.$on('printer:print',(blob) => {
      this.src = URL.createObjectURL(blob)
    })
  },
  methods: {
    loaded() {
      let iframe = this.$refs.print

      window.addEventListener('focus', () => {
        this.src = undefined
      }, {once: true})

      if(this.src) {
        setTimeout(() => {
          iframe.focus()
          iframe.contentWindow.print()
        },1)
      }
    }
  }
}
</script>