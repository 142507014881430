<template>
  <MainLayout> 
    <h1><i class='bx bxs-dashboard' ></i> Dashboard</h1>

    <template v-if="stats">
      <b-card-group columns>
        {{stats}}
        <!-- <b-card :header="$t('shipments.statuses.0')" class="text-center">
          <router-link :to="{name:'shipments',params:{filters:{'status':[0]}}}" class="no-icon">
            <div class="number">
              <i class='bx bx-edit' />
              {{stats.shipments[0]}}
            </div>
            <div class="label">{{$t('shipments.title')}}</div>
          </router-link>
        </b-card> -->
      </b-card-group>
    </template>

    <debug :data="stats" />

  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main";
import api from '@/api';
import Debug from '@/components/Debug';

export default {
  components: { MainLayout, Debug },
  data() {
    return {
      stats: null
    }
  },
  async created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      let {data} = await api.get('dashboard/statistics')

      this.stats = data
    }
  },
  metaInfo() {
    return {
      title: this.$t('dashboard.title'),
    }
  },
};
</script>

<style lang="scss" scoped>
  .number {
    font-size: 400%;
    line-height: 100%;
    font-weight: bold;
  }

  .label {
    font-size: 120%;
    font-weight: bold;
  }
</style>