<template>
  <div class="guest-layout">
    <div class="header">
      <ul class="nav nav-pills">
        <!-- <li v-for="locale in availableLocales" class="nav-item" :key="locale.code">
          <a class="nav-link ml-2" :class="{active: currentLocale == locale.code}" href="#" @click.stop="setLocale(locale.code)">{{locale.code}}</a>
        </li> -->
        <slot name="header"></slot>
      </ul>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import i18n, { availableLocales } from '../i18n'

export default {
  data() {
    return {
      availableLocales
    }
  },
  computed: {
    currentLocale() {
      return i18n.locale
    }
  },
  methods: {
    ...mapActions({
      setLocale: 'setLocale',
    }),
  }
}
</script>

<style lang="scss" scoped>
  @import "@/css/variables.scss";

  .guest-layout {
    background-color: $primary;
    // background-image: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" style="fill: %23060606;" width="170" height="200" viewBox="0 0 170 200"><g><path d="m126.75 296.999 34.543-105.436L0 108.907V86.433l185.618 95.122-37.822 115.444z" transform="matrix(.91586 0 0 .94982 0 -82.096)"/><path d="m0 126.04 142.895 73.228-32.019 97.731H0Z" transform="matrix(.91586 0 0 .94982 0 -82.096)"/></g></svg>');
    // background-repeat: no-repeat;
    // background-position: bottom left;
    // background-size: 50vmin;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .header {
      position: absolute;
      top: 1rem;
      right: 1rem;

      .nav-link {
        &:not(.active) {
          box-shadow: 0px 0px 4px 0px darken($primary, 25%);
        }

        &.active {
          background-color: $dark;
          color: white;
        }
      }
    }
  }
</style>