import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import applications from './modules/applications';
import users from './modules/users';
import i18n from '../i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: 'en',
    activitiesReversed: true,
  },
  getters: {
    locale: state => state.locale
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('setLocale', locale)
    },
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale
      i18n.locale = locale
    },
    setActivitiesReversed: (state,reversed) => {
      state.activitiesReversed = reversed
    },
  },
  modules: {
    auth,
    users,
    applications,
  },
  plugins: [createPersistedState({
    rehydrated: state => {
      i18n.locale = state.getters.locale
    }
  })]
});