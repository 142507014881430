<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <status class="small" v-bind="{item}" />
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4">
          <div class="col mb-4">

            <b-card class="mb-4">
              <template #header> 
                <i class='bx bxs-contact' />
                {{$t('applications.details')}}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="applications.first_name" icon="bx-purchase-tag" :value="item.first_name"/>
                  </div>
                  <div class="col">
                    <value label="applications.last_name" icon="bx-purchase-tag" :value="item.last_name"/>
                  </div>
                  <div class="col">
                    <value label="applications.middle_name" icon="bxs-purchase-tag" :value="item.middle_name"/>
                  </div>
                  <div class="col">
                    <value label="applications.user_id" icon="bx-id-card" :value="item.user_id"/>
                  </div>
                  <div class="col">
                    <value label="applications.gender" :icon="`bx-${item.gender.toLowerCase()}-sign`" :value="item.gender"/>
                  </div>
                  <div class="col">
                    <value label="applications.dob" icon="bx-calendar" :value="item.dob"/>
                  </div>
                  <div class="col">
                    <value label="applications.nib" icon="bx-barcode" :value="item.nib"/>
                  </div>
                  <div class="col">
                    <value label="applications.address" icon="bx-building-house" :value="item.address"/>
                  </div>
                  <div class="col">
                    <value label="applications.island" icon="bx-map-pin" :value="item.island"/>
                  </div>
                  <div class="col">
                    <value label="common.phone" icon="bx-phone" :value="item.phone">
                      <!-- <div v-if="item.phone" :class="{
                        'text-danger': !item.verified,
                        'text-success': item.verified
                        }">{{item.phone}}</div>
                      <div v-if="item.phone_diff || !item.phone" class="text-muted font-weight-normal">
                        <component :is="item.phone_diff ? 'del' : 'span'">{{item.original_phone}}</component>
                      </div> -->
                    </value>
                  </div>
                </div>
                <value label="common.email" icon="bx-envelope" :value="item.email"/>
              </dl>
            </b-card>

          </div>
          <div class="col mb-4">
            <b-card class="mb-4">
              <template #header> 
                <i class='bx bxs-credit-card-front' />
                {{$t('applications.options')}}
              </template>
              <dl>
                <value label="applications.design" icon="bx-paint-roll" class="text-capitalize" :value="item.design"/>
                <value label="applications.location" icon="bx-map" :value="item.location_id">
                  <span v-if="item.$meta.location">{{item.$meta.location.name}}</span>
                </value>
              </dl>
            </b-card>

            <b-card v-if="item.signature" class="mb-4">
              <template #header> 
                <i class='bx bxs-pen' />
                {{$t('applications.signature')}}
              </template>
              <b-overlay :show="!signature" rounded spinner-type="grow" spinner-variant="primary">
                <img v-if="signature" :src="signature" width="100%">
              </b-overlay>
            </b-card>

            <activity-card :item="item" />

          </div>
        </div>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemShow from '@/components/ItemShow'
import ActivityCard from '@/components/Cards/Activity'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar.vue'
import Status from '@/components/Status.vue'

export default {
  extends: ItemShow,
  components: { 
    MainLayout, 
    ActivityCard, 
    Value, 
    ActionBar, 
    Status,
  },
  data() {
    return {
      signature: null
    }
  },
  methods: {
    async fetched() {
      if(this.item.signature) {
        this.signature = await this.item.$getSignature()
      }
    },
  }
};
</script>